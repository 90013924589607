<template>
  <v-app>
    <v-app-bar color="background" app>
      <v-container>
        <v-row>
          <v-img max-width="14" class="ma-1" :src="require('@/assets/Logo.png')" />
          <span class="font-weight-black text-primary ml-1">TWO</span>
          <span class="font-weight-black text-secondary">SIX</span>
          <span class="font-weight-black text-primary mr-1">SEED</span>
          <v-spacer />
          <v-btn :to="QRGenerator">
          </v-btn>
          <v-btn href="https://twosixseed.com">
            Support your growth
          </v-btn>

        </v-row>
      </v-container>

    </v-app-bar>
    <v-container>
      <router-view />
    </v-container>


    <v-footer inset app padless tile color="background darken-1">
      <v-toolbar dense flat color="transparent">
        <v-img max-width="14" class="ma-1" :src="require('@/assets/Logo.png')" />
        <span class="font-weight-black text-primary ml-1">TWO</span>
        <span class="font-weight-black text-secondary">SIX</span>
        <span class="font-weight-black text-primary mr-1">SEED</span>
        <span class=" text-primary">| All Rights Reserved &copy;
          {{ new Date().getFullYear() }}</span>
        <v-spacer />
        <!-- <v-toolbar-items>
          <v-btn @click="openInNewTab(item.link)" color="primary" v-for="item in links" :key="item.icon" class="mx-2" icon
            small>
            <v-icon small>{{ item.icon }}</v-icon>
          </v-btn>
        </v-toolbar-items> -->

      </v-toolbar>
    </v-footer></v-app>
</template>

<script>


export default {
  name: 'App',

  data() {
    return {
      shareFab: null,
      dropdown: 0,
      links: [
        {
          title: "Facebook",
          icon: "fab fa-facebook",
          link: "https://facebook.com",
        },
        {
          title: "Twitter",
          icon: "fab fa-twitter",
          link: "https://twitter.com",
        },
        {
          title: "Youtube",
          icon: "fab fa-youtube",
          link: "https://youtube.com",
        },
        {
          title: "Instagram",
          icon: "fab fa-instagram",
          link: "https://instagram.com",
        },
        { title: "Slack", icon: "fab fa-slack", link: "https://slack.com" },
        {
          title: "Discord",
          icon: "fab fa-discord",
          link: "https://discord.com",
        },
      ],
    };
  },
};
</script>

<style>
#app {
  text-align: center;
}
</style>