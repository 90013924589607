<template>
  <v-row align-self-center>
    <v-spacer />
    <v-col cols="8" v-col-auto>
      <v-card flat color="background" align-self-center>
        <QrCodeGenerator />
      </v-card>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import QrCodeGenerator from '../components/QRGenerator.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    QrCodeGenerator,
  },
});
</script>


