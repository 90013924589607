<template>
   <v-container>
      <v-row>
         <v-col cols="12">
            <v-container>

               <v-container>
                  <v-row>
                     <v-img max-width="32" aspect-ratio="1" contain :src="require('@/assets/Logo.png')" />
                     <h1 class="font-weight-black text-primary ml-3">TWO</h1>
                     <h1 class="font-weight-black text-secondary">SIX</h1>
                     <h1 class="font-weight-black text-primary mr-1">SEED</h1>
                  </v-row>
                  <h1>QR Code Generator</h1>
               </v-container>
            </v-container>

            <v-alert type="info" class="mb-4">
               UTM parameters (Urchin Tracking Module) are used to track the performance of campaigns in Google Analytics.
               You can specify the source, medium, and campaign name to see how different traffic sources contribute to
               your
               website's success.
            </v-alert>
         </v-col>
      </v-row>
      <v-row>
         <v-col cols="6">
            <v-row>
               <v-col cols="12">
                  <v-text-field required v-model="url" label="Enter URL" outlined persistent-hint
                     hint="Enter the URL you want the QR code to link to." :rules="[urlRule]"></v-text-field>
               </v-col>
            </v-row>
            <v-row>
               <v-col cols="12">
                  <v-text-field v-model="utmSource" label="UTM Source" outlined persistent-hint
                     hint="Identify the source of the traffic (e.g., newsletter, facebook)."
                     :rules="[utmRule]"></v-text-field>
               </v-col>
            </v-row>
            <v-row>
               <v-col cols="12">
                  <v-text-field v-model="utmMedium" label="UTM Medium" outlined persistent-hint
                     hint="Identify the medium of the traffic (e.g., email, social)." :rules="[utmRule]"></v-text-field>
               </v-col>
            </v-row>
            <v-row>
               <v-col cols="12">
                  <v-text-field v-model="utmCampaign" label="UTM Campaign" outlined persistent-hint
                     hint="Name the campaign that the QR code is part of (e.g., spring_sale)."
                     :rules="[utmRule]"></v-text-field>
               </v-col>
            </v-row>
            <v-row>
               <v-col cols="12">
                  <v-file-input v-model="logo" label="Upload Logo" outlined persistent-hint
                     hint="Upload an optional logo to embed in the center of the QR code."
                     @change="handleFileUpload"></v-file-input>

               </v-col>
            </v-row>
            <v-row>
               <v-col cols="12">
                  <v-divider class="my-4"></v-divider>
               </v-col>
            </v-row>
         </v-col>
         <v-col cols="6" fill-height>
            <v-container>
               <v-row>
                  <v-spacer />
                  <v-btn :disabled="!isValid" @click="generateQrCode" color="primary">Generate QR Code</v-btn>
                  <v-btn v-if="qrCodeSrc" @click="downloadQrCode" color="success">Download QR Code</v-btn>
                  <v-spacer />
               </v-row>
            </v-container>
            <v-card :color="qrCodeSrc ? 'white' : 'grey'" align-self-center>
               <v-img v-if="qrCodeSrc" :src="qrCodeSrc" alt="QR Code" aspect-ratio="1" contain></v-img>
            </v-card>

         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import QRCode from 'qrcode';

export default {
   data() {
      return {
         url: '',
         utmSource: '',
         utmMedium: '',
         utmCampaign: '',
         logo: null,
         qrCodeSrc: '',
         urlRule: v => {
            const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
            return pattern.test(v) || 'Enter a valid URL (including TLD)';
         },
         utmRule: v => /^[a-zA-Z0-9_-]*$/.test(v) || 'Only alphanumeric characters, underscores, and dashes are allowed',
      };
   },
   computed: {
      isValid() {
         return this.url && /^[a-zA-Z0-9_-]*$/.test(this.utmSource) && /^[a-zA-Z0-9_-]*$/.test(this.utmMedium) && /^[a-zA-Z0-9_-]*$/.test(this.utmCampaign);
      }
   },
   methods: {
      handleFileUpload(event) {
         const file = event.target.files[0];
         if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
               this.logo = e.target.result;
            };
            reader.readAsDataURL(file);
         }
      },
      buildUrlWithUTM(url, utmSource, utmMedium, utmCampaign) {
         try {
            // Ensure the URL has a protocol
            if (!/^https?:\/\//i.test(url)) {
               url = 'https://' + url;
            }
            const urlObj = new URL(url);
            if (utmSource) urlObj.searchParams.set('utm_source', utmSource);
            if (utmMedium) urlObj.searchParams.set('utm_medium', utmMedium);
            if (utmCampaign) urlObj.searchParams.set('utm_campaign', utmCampaign);
            return urlObj.toString();
         } catch (e) {
            alert('Invalid URL');
            return null;
         }
      },
      async generateQrCode() {
         if (!this.isValid) {
            alert('Please fix the errors before generating the QR code');
            return;
         }

         const trackableUrl = this.buildUrlWithUTM(this.url, this.utmSource, this.utmMedium, this.utmCampaign);

         if (!trackableUrl) return;

         try {
            const canvasSize = 1000;
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = canvasSize;
            canvas.height = canvasSize;

            await QRCode.toCanvas(canvas, trackableUrl, {
               errorCorrectionLevel: 'H',
               margin: 4,
               width: canvasSize
            });

            if (this.logo) {
               const logoSize = canvas.width / 5;
               const padding = 5;
               const x = (canvas.width - logoSize - padding * 2) / 2;
               const y = (canvas.height - logoSize - padding * 2) / 2;

               context.clearRect(x, y, logoSize + padding * 2, logoSize + padding * 2);
               context.fillStyle = 'white';
               context.fillRect(x, y, logoSize + padding * 2, logoSize + padding * 2);

               const image = new Image();
               image.src = this.logo;
               image.onload = () => {
                  context.drawImage(image, x + padding, y + padding, logoSize, logoSize);
                  this.qrCodeSrc = canvas.toDataURL();
               };
            } else {
               this.qrCodeSrc = canvas.toDataURL();
            }
         } catch (err) {
            console.error(err);
         }
      },
      downloadQrCode() {
         const link = document.createElement('a');
         link.href = this.qrCodeSrc;
         link.download = 'qr_code.png';
         link.click();
      }
   }
};
</script>

<style>
.v-application {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   text-align: center;
   color: #2c3e50;
   margin-top: 60px;
}
</style>