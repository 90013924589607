// src/plugins/vuetify.js

// Import Vuetify
import { createVuetify } from 'vuetify'

// Import the Vuetify styles
import 'vuetify/styles'

// Define your custom dark theme
const myCustomDarkTheme = {
  dark: true,
  colors: {
    primary: '#4CAE4E',
    secondary: '#E91E63',
    accent: '#F48FB1',
    background: '#000E13',
  }
}

// Create the Vuetify instance
const vuetify = createVuetify({
  theme: {
    defaultTheme: 'myCustomDarkTheme',
    themes: {
      myCustomDarkTheme,
    },
  },
})

export default vuetify